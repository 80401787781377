<template>
  <el-select
    :value="value"
    :size="size"
    clearable
    placeholder="Выберите единицу измерения"
    @change="handleInput"
  >
    <el-option
      v-for="item in units"
      :key="item"
      :label="item"
      :value="item"
    >
    </el-option>
  </el-select>

</template>

<script>
import {variables} from "@/componentsCompany/cherkizovo/variables";

export default {
  name: "element-scale-unit-select",
  mixins: [],
  components: {},
  props: {
    value: {type: String},
    size: {type: String, default: ''},
  },

  data() {
    return {
      units: variables.units,
    }
  },

  methods: {
    handleInput(e) {
      this.$emit('input', e)
    },
  },

}
</script>

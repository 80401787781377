<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >

    <el-form-item prop="part_names">
      <library-edit-parts-form
        style="margin-top: 20px; margin-bottom:10px;"
        :scale-id="scale.id"
        scale-type="design"
        :is-clone="isClone"
        :design-scale-scopes="scopes"
        :parts.sync="scale.parts"
      ></library-edit-parts-form>
    </el-form-item>

<!--    <el-form-item label="Уровень достижения цели">-->
<!--      <el-form-item label="Цель выполнена полностью (100%)" prop="pay_100" class="design-scale-pay-form-item">-->
<!--        <el-input-->
<!--          type="textarea"-->
<!--          :autosize="{ minRows: 2}"-->
<!--          v-model="scale.pay_100"-->
<!--        >-->
<!--        </el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="Цель выполнена не полностью, незначительное отклонение (75%)" prop="pay_75" class="design-scale-pay-form-item">-->
<!--        <el-input-->
<!--          type="textarea"-->
<!--          :autosize="{ minRows: 2}"-->
<!--          v-model="scale.pay_75"-->
<!--        >-->
<!--        </el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="Цель выполнена не полностью, допустимые отклонения (50%)" prop="pay_50" class="design-scale-pay-form-item">-->
<!--        <el-input-->
<!--          style="width: 100%"-->
<!--          type="textarea"-->
<!--          :autosize="{ minRows: 2}"-->
<!--          v-model="scale.pay_50"-->
<!--        >-->
<!--        </el-input>-->
<!--      </el-form-item>-->


<!--    </el-form-item>-->

    <el-form-item label="Единица измерения" prop="unit">
      <element-scale-unit-select
        v-model="scale.unit"
        style="width: 100%"
      ></element-scale-unit-select>
    </el-form-item>

<!--    <el-form-item label="Плановое значение" prop="plan">-->
<!--      <el-input-->
<!--        v-model="scale.plan"-->
<!--      ></el-input>-->
<!--    </el-form-item>-->

<!--    <el-form-item label="Фактическое значение" prop="fact">-->
<!--      <el-select-->
<!--        v-model="scale.fact"-->
<!--        style="width:100%"-->
<!--      >-->
<!--        <el-option-->
<!--          v-for="scope in scopes"-->
<!--          :key="scope.id"-->
<!--          :label="scope.target"-->
<!--          :value="scope.id"-->
<!--        ></el-option>-->
<!--      </el-select>-->
<!--    </el-form-item>-->

    <el-form-item label="Ответственный за KPI" prop="responsible">
      <el-input
        v-model="scale.responsible"
      ></el-input>
    </el-form-item>

  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";
import ElementScaleUnitSelect from "@/componentsCompany/cherkizovo/elementScales/ElementScaleUnitSelect.vue";
import LibraryEditPartsForm from "@/componentsCompany/cherkizovo/elementScales/progressive/libraryEditPartsTable.vue";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {LibraryEditPartsForm, ElementScaleUnitSelect},

  props: {},

  computed: {},

  watch: {},

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });
    requestSender('get', 'scale/templates-list', {})
      .then(data => {
        // У нас может быть удаленный или залоченный темплейт. Нужно его тоже пушнуть в массив
        if (this.scale.parent) {
          if (!data.scales.design.find(t => t.id === this.scale.parent.id)) {
            data.scales.design.push({
              id: this.scale.parent.id,
              name: this.scale.parent.name,
            })
          }
        }
        this.scaleTemplates = data.scales.design;
        this.scale.parent_id = this.scaleTemplates[0].id;
        this.scopes = this.scaleTemplates[0].scopes;
      })
  },

  data() {
    const validatePartNames = (rule, value, callback) => {
      this.scale.parts.forEach(part => {
        if( !part.part_1 || !part.part_2 || !part.part_3 ){
          callback(new Error('Part обязательны для заполнения'));
        }
      })
      callback();
    };
    return {
      scaleTemplates: [],
      scopes: [],

      scale: {
        id: null,
        parts: [],
        parent: null,

        parent_id: null,
        unit: null,
        pay_100: null,
        pay_75: null,
        pay_50: null,
        plan: null,
        fact: null,
        responsible: null,
      },

      rules: {
        parent_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        unit: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        // plan: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        pay_100: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        pay_75: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        pay_50: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        part_names: [{ validator: validatePartNames, trigger: 'change'},],
      }

    }
  },

  methods: {}
}
</script>

<style lang="scss">
.design-scale-pay-form-item{
  display: flex;
  padding-left:20px;

  .el-form-item__label{
    width: 40%;
  }
  .el-form-item__content{
    width:60%;
    padding-right: 20px;
    margin-bottom: 20px;
  }
}
</style>